body {
  background-color: #fafafa;
}

.checking_area {
  text-align: left;
  white-space: pre-wrap;
  border: 0;
  resize: none;
  overflow: auto;
  font-size: 105%;
  line-height: 140%;
  font-family: Times;
}
.review {
  /* background-color: rgba(255, 0, 191, 0.11); */
}
.terms_highlight {
  background-color: rgba(182, 244, 244, 0.6);
}
.review:hover {
  cursor: pointer;
  background-color: rgba(255, 21, 0, 0.44);
}

.summary {
  border-radius: 5px;
  /*background-color: rgba(0, 196, 255, 0.15);*/
  /*border: 2px solid #bdbdbd3d;*/
}
.line {
  position: absolute;
  background: green;
  height: 1px;
  z-index: 1; /*这里设置大于2，即可看到真正连线的其实是两个元素的中心坐标*/
}

#file_create {
  top: 25%;
  border: 3px solid #f8f8f8;
  visibility: hidden;
  position: absolute;
  z-index: 999;
  opacity: 1;
  overflow: hidden;
  background-color: white;
  text-align: center;
  margin-top: 10%;
  margin-left: 30%;
  border-radius: 10px;
  width: 30%;
}

#file_upload {
  top: 25%;
  border: 3px solid #f8f8f8;
  visibility: hidden;
  position: absolute;
  z-index: 999;
  opacity: 1;
  overflow: hidden;
  background-color: white;
  text-align: center;
  margin-top: 10%;
  margin-left: 30%;
  border-radius: 10px;
  width: 30%;
}

#upload_block {
  position: relative;
  display: inline-block;

  border-radius: 4px;
  overflow: hidden;
  text-decoration: none;
  text-indent: 0;
  line-height: 20px;
  height: 5.5vh;
}
#upload_block input {
  width: 100%;
  opacity: 0;
}
#upload_block:hover {
  text-decoration: none;
}
#upload_block span {
  position: absolute;
}

#upload_span_button {
  left: 0;
  font-size: 2.5vh;
}

#upload_span_name {
  left: 6vw;
  top: 1.2vh;
  font-size: 1.5vh;
}

.highlight {
  text-decoration: underline solid rgb(45, 150, 248) 20%;
}

.highlight:hover {
  background: rgba(84, 225, 250, 0.432);
  cursor: pointer;
}

#content1 iframe {
  height: 65.7vh;
}

#review-body {
  max-height: 67.5vh;
  overflow: auto;
}

#summarized {
  text-align: left;
  height: 90vh;
  width: 90%;
  margin: 1% auto;
  border: 0;
  border-radius: 5px;
  background-color: rgba(241, 241, 241, 0.98);
  padding: 2%;
  resize: none;
  overflow: auto;
  font-size: 90%;
}

.sum_para {
  /*positive: relative;*/
  background-color: rgba(0, 196, 255, 0.15);
  border-radius: 5px;
  border: 2px solid #bdbdbd3d;
}

#summarized_right {
  max-height: 67.5vh;
  overflow: auto;
  white-space: pre-wrap;
  margin: 1% 1%;
  overflow: auto;
  font-size: 115%;
}

/*.sum_para:hover {*/
/*    background-color: azure;*/
/*}*/

.para_jump {
  text-decoration: none;
  color: #000000;
}

.para_jump:hover {
  background-color: rgba(0, 196, 255, 0.15);
  border-radius: 5px;
  border: 2px solid #bdbdbd3d;
  cursor: pointer;
}

.similar_sentence {
  white-space: normal !important;
}

.long_sentence {
  width: 430px !important;
}

.review_unit {
  max-height: 60vh;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  resize: vertical;
}

.review_container {
  position: relative;
  /* width: 100%; */
  padding: 1vh;
  margin: 2vh 0;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  overflow: hidden;
  white-space: normal;
  transition: all 0.5s linear;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2) !important; */
  max-height: 8vh;
  /*background-image: linear-gradient(45deg, #bdbcbc, #ffffff);*/
}

.review_container:hover {
  max-height: 60vh !important;
  background-color: azure;
  overflow: scroll;
  white-space: normal;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.review_wrap {
  position: relative;
  left: 0;
  top: 0;
}

::-webkit-scrollbar {
  display: none !important; /* Chrome Safari */
}

.ChatBox {
  position: fixed;
  bottom: 5vh;
  right: 5vw;
  border-radius: "50%";
}

.ChatContainer {
  height: 60vh;
  width: 55vh;
  border-radius: 3vh;
}

.AI_Avatar {
  backgroundcolor: red;
}

.terms_highlight:hover {
  cursor: pointer;
}

.loginInput {
  font-size: 2vh;
  width: 95%;
  border-radius: 1.79vh;
  border: 0;
  height: 100%;
  padding: 0 0 0 5%;
}
