:root {
  --primary: #2c82c1;
  --secondary: #b6daf3;
}

.subscriptionCol {
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0 2rem 0;
}
.subscriptionBox {
  display: flex;
  gap: 1rem;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 15px;
}

.subscriptionText {
  display: flex;
  flex-direction: column;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  max-width: 140px;
}

.subscriptionRenew {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #3fa2f7;
  cursor: pointer;
}

.mainButtonCol {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 0.5rem 0;
}

.mainButton {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  border: none;
  width: 382px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  color: white;
}

.mainButton.contractButton {
  background-color: #2c82c1;
}

.mainButton.contractButton :hover {
  cursor: pointer;
}

.mainButton.draftingButton {
  background-color: #b6daf3;
}

.searchCol {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 2rem 0;
  gap: 16px;
}

.searchBox {
  display: flex;
  gap: 12px;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  padding: 12px;
  width: 50%;
}

.searchInput {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 800px;
  color: #2c82c1;
  border: none;
}

.searchInput:focus {
  border: none;
  outline: none;
}

.searchButton {
  background-color: var(--primary);
  border-radius: 10px;
  box-shadow: none;
  width: 33%;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 23.48px;
  color: white;
  padding: 0.5rem;
}

.orderBox {
  border-radius: 4px;
  padding: 12px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  cursor: pointer;
  border: 1px solid #d4d4d4;
  width: 33%;
}

.blockGrid {
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
}

.uploadContainer {
  align-self: center !important;
  align-items: center;
  background-color: #dfedf7;
  border: 2px dashed #9bbcd4;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  min-height: 145px;
}

.tableContainer {
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  overflow: auto;
}

.tableCellText {
  font-family: "Raleway" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 21px !important;
}

.document .tableCellText {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
}

.tagButtons {
  display: flex;
  min-height: 40px !important;
  margin-bottom: 0 !important;
  gap: 17px;
  align-items: center;
}

.title {
  display: flex;
  min-height: 30px !important;
  gap: 70px;
  align-items: center;
}

.titleText {
  font-family: "Raleway" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1.5rem !important;
  line-height: 21px !important;
  color: #959595;
}
