.login-form {
  font-size: 14px;
}

@media (max-height: 720px) {
  .login-form {
    font-size: 8px;
  }
}

@media (max-width: 960px) {
  .login-background {
    display: none;
  }

  .login-form {
    position: relative !important;
    left: 0 !important;
    margin: 0 auto !important;
  }
}

@media (min-width: 960px) {
  .logo {
    width: 193.8px;
  }

  .login-background {
    font-size: 14px;
  }
}

@media (min-width: 1120px) {
  .logo {
    width: 257.3px;
  }

  .login-background {
    font-size: 18px;
  }
}

@media (min-width: 1600px) {
  .logo {
    width: 339px;
  }

  .login-background {
    font-size: 24px;
  }
}
